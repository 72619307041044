<template>
  <div class="container">
    <ShareDialog
      :visibleShareDialog="visibleShareDialog"
      :practice="practice"
      @toggleVisibleShareDialog="toggleVisibleShareDialog"
    />
    <CopyDialog
      v-if="practice"
      :visibleCopyDialog="visibleCopyDialog"
      :practiceId="practice.id"
      @toggleVisibleCopyDialog="toggleVisibleCopyDialog"
    />
    <Breadcrumb />
    <el-alert
      v-if="practice.user_exams.length > 0"
      class="mb-3"
      type="error"
      :closable="false"
    >
      <template slot="title">
        <span>{{ $t("practice.form.alreadyTakenExam") }}</span>
        <span class="action-icon" @click="() => toggleVisibleCopyDialog(true)">
          <i class="fas fa-copy" />{{ $t("column.copy") }}
        </span>
      </template>
    </el-alert>
    <h3>
      {{ this.pageTitle }}
    </h3>
    <hr class="separate-line" />
    <div v-if="practice">
      <el-form label-width="100px" :model="practice" ref="practiceForm">
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.name") }}</el-row></template
          >
          <el-row>
            <el-col :span="20">
              <el-input v-model="practice.exam.title" />
            </el-col>
            <el-col style="text-align: right" :span="4">
              <el-button
                type="primary"
                @click="
                  () => {
                    toggleVisibleShareDialog(true);
                  }
                "
              >
                <i class="fas fa-lock-open" />
                {{ $t("practice.form.share") }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.needToTimer") }}</el-row></template
          >
          <el-row>
            <el-col :span="6">
              <el-radio-group v-model="practice.has_timing">
                <el-radio-button :label="0">{{
                  $t("practice.form.doNotNeedToTimer")
                }}</el-radio-button>
                <el-radio-button :label="1">{{
                  $t("practice.form.needToTimer")
                }}</el-radio-button>
              </el-radio-group>
            </el-col>
            <el-col v-if="practice.has_timing" :span="18">
              <el-input
                style="width: 50%"
                placeholder="Minutes"
                v-model="practice.timing_minutes"
              >
                <template slot="prepend">
                  <i class="el-icon-alarm-clock" />
                </template>
                <template slot="append">{{ $t("practice.form.min") }}</template>
              </el-input>
              <el-input
                style="width: 50%"
                placeholder="Seconds"
                v-model="practice.timing_seconds"
              >
                <template slot="append">{{ $t("practice.form.sec") }}</template>
              </el-input>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.limit") }}</el-row></template
          >
          <el-row>
            <el-col :span="6">
              <el-radio-group v-model="practice.has_expire">
                <el-radio-button :label="0">{{
                  $t("practice.form.noneLimit")
                }}</el-radio-button>
                <el-radio-button :label="1">{{
                  $t("practice.form.hasLimit")
                }}</el-radio-button>
              </el-radio-group>
            </el-col>
            <template v-if="practice.has_expire">
              <el-col :span="8">
                <el-date-picker
                  type="date"
                  style="width: 100%"
                  v-model="practice.expire_start"
                />
              </el-col>
              <el-col style="text-align: center;" :span="2">～</el-col>
              <el-col :span="8">
                <el-date-picker
                  type="date"
                  style="width: 100%"
                  v-model="practice.expire_end"
                />
              </el-col>
            </template>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{ $t("practice.form.show") }}</el-row></template
          >
          <el-row>
            <el-radio-group v-model="practice.show">
              <el-radio-button label="COMPLETION_ONLY">{{
                $t("practice.form.completionOnly")
              }}</el-radio-button>
              <el-radio-button label="CORRECTNESS">{{
                $t("practice.form.correctness")
              }}</el-radio-button>
              <el-radio-button label="CORRECTNESS_AND_EXPLANATION">{{
                $t("practice.form.correctnessAndExplanation")
              }}</el-radio-button>
            </el-radio-group>
          </el-row>
        </el-form-item>
        <el-form-item>
          <template slot="label"
            ><el-row>{{
              $t("practice.form.practiceTemplate")
            }}</el-row></template
          >
          <el-row>
            <el-radio-group v-model="practice.template_type">
              <el-radio-button label="ORIGINAL">{{
                $t("practice.form.byQuestion")
              }}</el-radio-button>
              <el-radio-button label="DigitalSAT">New Digital SAT</el-radio-button>
              <el-radio-button label="SAT">Old Paper-Based SAT</el-radio-button>
              <el-radio-button label="ACT">ACT</el-radio-button>
              <el-radio-button label="TOEFL">TOEFL</el-radio-button>
            </el-radio-group>
          </el-row>
        </el-form-item>
      </el-form>
      <el-row>
        <el-col style="text-align: center">
          <el-button @click="updatePractice" type="primary">
            {{ $t("button.save") }}
          </el-button>
        </el-col>
      </el-row>
      <hr class="separate-line" />
      <el-row>
        <el-col :span="18" style="text-align: center">
          <router-link
            v-if="practice.user_exams.length === 0"
            :to="{
              name: 'AddQuestionsToPractice',
              params: { practiceId: $route.params.practiceId }
            }"
          >
            <el-button style="width: 600px" type="primary">
              <span>{{
                `${$t("practice.form.currentQuestionCount")}${
                  practice.exam.questions.length
                }`
              }}</span>
              &nbsp;&nbsp;
              <span style="margin-left: 16px">
                {{ $t("practice.form.addNewQuestion") }}<i class="fa fa-plus" />
              </span>
            </el-button>
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'PracticeResults',
              params: { practiceId: $route.params.practiceId }
            }"
          >
            <el-button type="primary">
              <i class="fa fa-eye" />{{ $t("practice.form.testResults") }}
            </el-button>
          </router-link>
        </el-col>
        <el-col :span="6">
          <router-link
            :to="{
              name: 'Practice',
              params: { practiceId: $route.params.practiceId }
            }"
          >
            <el-button type="primary">
              <i class="fa fa-eye" />{{ $t("practice.form.preview") }}
            </el-button>
          </router-link>
        </el-col>
      </el-row>
      <el-row
        v-if="!hasChangedQuestionOrder && practice.exam.questions.length > 0"
      >
        <el-col>
          <span style="color: red; font-size: 12px"
            >* {{ $t("practice.form.dragQuestionsToAdjustOrder") }}</span
          >
        </el-col>
      </el-row>
      <el-row
        v-if="hasChangedQuestionOrder"
        :gutter="4"
        type="flex"
        justify="center"
      >
        <el-col :span="3">
          <el-button @click="updateQuestionOrder" type="primary">
            {{ $t("practice.form.saveQuestionOrder") }}
          </el-button>
        </el-col>
        <el-col :span="3">
          <el-button @click="resetQuestionOrder" type="danger">
            {{ $t("practice.form.cancelSequenceChange") }}
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <draggable
          :disabled="practice.user_exams.length > 0"
          :list="practice.exam.questions"
          class="list-group"
          ghost-class="ghost"
          @start="dragging = true"
          @end="dragging = false"
          :move="moveQuestionOrder"
        >
          <el-card
            class="list-group-item mb-3"
            v-for="(question, index) in practice.exam.questions"
            :key="question.id"
          >
            <el-row>
              <el-col :span="1">{{ index + 1 }}</el-col>
              <el-col :span="2">
                <b>Pid:</b>{{ ` ${question.question_id}` }}
              </el-col>
              <el-col :span="2">
                <kbd>{{ QuestionAdapter(question).question.order }}</kbd>
              </el-col>
              <el-col :span="18">
                <div v-if="QuestionAdapter(question).question.content">
                  {{ QuestionAdapter(question).question.content }}
                </div>
                <span v-if="QuestionAdapter(question).question.options">
                  <div
                    :key="option.id"
                    v-for="option in QuestionAdapter(question).question.options"
                    v-html="`${option.letter}) ${option.title}`"
                  >
                  </div>
                </span>
              </el-col>
              <el-col :span="1">
                <span @click="removeQuestion(question.id)">
                  <i class="fas fa-trash-alt warning-icon" />
                </span>
              </el-col>
            </el-row>
          </el-card>
        </draggable>
      </el-row>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Breadcrumb from "@/components/Breadcrumb";
import ShareDialog from "@/components/practices/ShareDialog";
import CopyDialog from "@/components/practices/CopyDialog";
import QuestionAdapter from "@/components/practices/QuestionAdapter";
import practiceApi from "@/apis/practices";
import Practice from "@/views/practices/Practice";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: { Breadcrumb, ShareDialog, CopyDialog, draggable },
  mixins: [],
  props: [],
  data() {
    return {
      practice: null,
      originalQuestionOrder: [],
      visibleShareDialog: false,
      visibleCopyDialog: false,
      hasChangedQuestionOrder: false
    };
  },
  computed: {
    pageTitle() {
      return `${this.$t("pageTitle.editPractices")}`;
    }
  },
  watch: {},
  async created() {
    this.fetchPractice();
  },
  methods: {
    async fetchPractice() {
      const { practice } = await practiceApi.fetchPractice(
        this.$route.params.practiceId
      );
      this.practice = new Practice(practice);
      this.originalQuestionOrder = this.practice.exam.questions.map(
        question => ({ ...question })
      );
    },
    toggleVisibleShareDialog(type) {
      this.visibleShareDialog = type;
    },
    toggleVisibleCopyDialog(type) {
      this.visibleCopyDialog = type;
    },
    async removeQuestion(examQuestionId) {
      try {
        await practiceApi.removeQuestions(examQuestionId);
        this.fetchPractice();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateQuestionOrder() {
      const questions = this.practice.exam.questions.map((question, index) => ({
        order: index + 1,
        exam_question_id: question.id
      }));
      try {
        await practiceApi.refreshQuestionsOrder({ questions });
        this.hasChangedQuestionOrder = false;
        this.fetchPractice();
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    resetQuestionOrder() {
      this.practice = {
        ...this.practice,
        exam: {
          ...this.practice.exam,
          questions: this.originalQuestionOrder.map(question => ({
            ...question
          }))
        }
      };
      this.hasChangedQuestionOrder = false;
    },
    moveQuestionOrder() {
      this.hasChangedQuestionOrder = true;
    },
    async updatePractice() {
      if (
        this.practice.has_timing &&
        Number(this.practice.timing_minutes) >= 0 &&
        (Number(this.practice.timing_seconds) < 0 ||
          Number(this.practice.timing_seconds) > 60)
      ) {
        this.$message.warning(this.$t("practice.form.pleaseFillValidTime"));
        return;
      }
      if (
        this.practice.has_expire &&
        !this.practice.expire_start &&
        !this.practice.expire_end
      ) {
        this.$message.warning(this.$t("practice.form.pleaseFillValidDeadline"));
        return;
      }
      try {
        await practiceApi.updatePractice(this.practice);
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    QuestionAdapter(question) {
      return QuestionAdapter(question);
    }
  }
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.list-group-item {
  padding: 0px 20px;
  cursor: move;
}
</style>
